<template>
  <div>
    <titulo title="USUARIOS.DADOS_PESSOAIS" />
    <b-row class="flex-column flex-sm-row">
      <b-col cols="auto" class="col-sm-4" v-if="form.codigo !== undefined">
        <input-text
          ref="codigo"
          v-model="form.codigo"
          :label="$t('PESSOAS.CODIGO_PESSOA')"
          :disabled="true"
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-text
          ref="nome"
          v-model="form.nome"
          :label="$t('PESSOAS.NOME')"
          placeholder="Digite nome e sobrenome"
          required
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-select-search
          ref="sexo"
          :options="listaSexo"
          v-model="form.sexo"
          :label="$t(`PESSOAS.SEXO`)"
          placeholder="Selecione ou digite"
          required
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-mask
          ref="cpf"
          type="text"
          v-model="form.cpf"
          :label="$t('PESSOAS.CPF')"
          :validate="validarCpfCnpj"
          :mask="['###.###.###-##']"
          placeholder="Digite apenas números"
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-text
          ref="rg"
          v-model="form.identidade"
          :label="$t('PESSOAS.RG')"
          placeholder="Digite com pontuação conforme documento oficial"
          :maxLength="50"
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-text
          type="text"
          ref="orgaoExpedidor"
          v-model="form.orgaoExpedidor"
          :label="$t('PESSOAS.ORGAO_EXPEDIDOR')"
          :maxLength="50"
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-date-only-btn
          ref="dataExpedicao"
          v-model="form.dataExpedicao"
          :label="$t('PESSOAS.DATA_EXPEDICAO')"
          :dataPassado="true"
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-date-only-btn
          ref="dataNascimento"
          v-model="form.dataNascimento"
          :label="$t('PESSOAS.DATA_NASCIMENTO')"
          :dataPassado="true"
          required
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-mask
          ref="registroNascimento"
          type="text"
          v-model="form.registroNascimento"
          :label="$t('PESSOAS.REGISTRO_NASCIMENTO')"
          :mask="['######.##.##.####.#.#####.###.#######-##']"
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-select-search
          ref="estadoCivil"
          :options="listaEstadoCivil"
          v-model="form.estadoCivil"
          :label="$t('PESSOAS.ESTADO_CIVIL')"
          placeholder="Selecione ou digite"
          required
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-select-search
          ref="nacionalidade"
          :options="listaNacionalidades"
          v-model="form.nacionalidadeId"
          :label="$t(`PESSOAS.NACIONALIDADE`)"
          required
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-text
          ref="naturalidade"
          v-model="form.naturalidade"
          :label="$t('PESSOAS.NATURALIDADE')"
          placeholder="Selecione ou digite"
          type="text"
        />
      </b-col>
      <b-col cols="auto" class="col-sm-4">
        <input-select-search
          ref="religiao"
          :options="listaReligiao"
          v-model="form.religiaoId"
          :label="$t(`PESSOAS.RELIGIAO`)"
          placeholder="Selecione ou digite"
          required
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="3"
        class="d-flex flex-column align-items-start mb-5 mt-5"
      >
        <titulo title="Foto" />
        <div class="d-flex flex-column align-items-start">
          <img
            alt="foto aluno"
            :src="fotoExistente || semFoto"
            class="foto-aluno cover"
          />
          <div
            class="d-flex flex-column justify-items-center align-items-center mt-2"
          >
            <span v-if="!fotoExistente" class="d-block pr-2">{{
              $t(`PESSOAS.FOTO_NAO_VINCULADA`)
            }}</span>
            <b-button variant="primary" @click="abrirModalFoto">
              {{ 'Adicionar foto' }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <modal-foto
      :exibir="mostrarModalFoto"
      @fechar="fecharModalFoto"
      @salvarFoto="salvarFoto"
    />
  </div>
</template>

<script>
// Aux & utils
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';

// services
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import PessoaService from '@/common/services/pessoa/pessoa.service.js';

//components
import Titulo from '@/components/headers/Titulo';
import ModalFoto from '@/components/foto/ModalFoto.vue';

import {
  InputText,
  InputMask,
  InputSelectSearch,
  InputDateOnlyBtn,
} from '@/components/inputs';

export default {
  props: ['form', 'dadosEndereco'],
  components: {
    Titulo,
    InputText,
    InputMask,
    InputSelectSearch,
    InputDateOnlyBtn,
    ModalFoto,
  },
  data() {
    return {
      mostrarModalFoto: false,
      estadoEndereco: [[]],
      municipioEndereco: [[]],
      foto: [],
      id: this.$route.params.id,
      fotoExistente: '',
      semFoto: require('../../../../assets/images/users/user-photo-input.png'),
      fotoNome: '',
      formEstadoId: '',
      listaPaises: [],
      listaEstados: [],
      listaMunicipios: [],
      listaNacionalidades: [],
      listaEstadoCivil: [],
      listaSexo: [],
      numeroDeAtivacao: 0,
      listaReligiao: [],
    };
  },
  mounted() {
    this.buscarNacionalidades();
    this.buscarEstadoCivil();
    this.buscarSexo();
    this.buscarReligiao();
    this.buscarFoto();
  },
  computed: {
    validarCpfCnpj() {
      let checagemCpf;
      if (this.form.cpf) {
        checagemCpf = helpers.validarCpfCnpj(this.form.cpf);
      }
      return checagemCpf;
    },
  },  watch: {
    dadosEndereco() {
      this.formEstadoId = this.dadosEndereco.formEstadoId;
      this.form.naturalidadeId = this.dadosEndereco.formMunicipioId;
    },},
  methods: {
    salvarFoto(base64) {
      this.form.fotoUpload = base64.replace('data:image/jpeg;base64,', '');
      this.form.foto = this.form.nome;
      this.fotoExistente = base64;
    },
    abrirModalFoto() {
      this.mostrarModalFoto = true;
    },
    fecharModalFoto() {
      this.mostrarModalFoto = false;
    },
    buscarFoto() {
      if (this.$route.params.id !== undefined) {
        this.$store.dispatch(START_LOADING);
        PessoaService.getFoto(this.id)
          .then(({ data }) => {
            this.fotoExistente = 'data:image/jpeg;base64,' + data.documento;
            this.fotoNome = data.nomeArquivo;
          })
          .catch(() => {
            // mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },
    buscarEstadoCivil() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('estado-civil')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.listaEstadoCivil = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarSexo() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('sexo')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.listaSexo = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarReligiao() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('religioes')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.listaReligiao = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarNacionalidades() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('nacionalidade')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.listaNacionalidades = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>

<style>
.foto-aluno {
  border: solid 1px #000;
  /* width: fit-content; */
  max-width: 190px;
  max-height: 190px;
  background: gray;
  display: block;
  object-fit: cover;
}
</style>
