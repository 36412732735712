<template>
  <div class="container">
    <div id="app" class="web-camera">
      <div v-show="isCameraOpen && isLoading" class="camera-loading">
        <ul class="loader-circle">
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>

      <div
        v-if="isCameraOpen"
        v-show="!isLoading"
        class="camera-box"
        :class="{ flash: isShotPhoto }"
      >
        <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

        <video ref="camera" :width="300" :height="300" autoplay></video>
      </div>
      <div class="camera-button">
        <b-button
          class="button is-rounded"
          :variant="!isCameraOpen ? 'primary' : null"
          @click="toggleCamera"
        >
          <span v-if="!isCameraOpen">Abrir câmera</span>
          <span v-else>Fechar câmera</span>
        </b-button>
        <div v-if="isCameraOpen && !isLoading">
          <b-button @click="takePhoto" variant="primary">
            Registrar foto
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mensagem from '@/common/utils/mensagem';

export default {
  name: 'WebCam',
  data() {
    return {
      isCameraOpen: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
    };
  },
  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: {
          width: { ideal: 500 },
          height: { ideal: 500 },
        },
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          if (this.$refs.camera !== undefined) {
            this.$refs.camera.srcObject = stream;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.isCameraOpen = false;
          mensagem.warning('Câmera Bloqueada', error);
        });
    },

    stopCameraStream() {
      if (this.$refs.camera !== undefined) {
        let tracks = this.$refs.camera.srcObject.getTracks();

        tracks.forEach((track) => {
          track.stop();
        });
      }
    },

    takePhoto() {
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = 500;
      tempCanvas.height = 500;
      const tempContext = tempCanvas.getContext('2d');
      
      const videoWidth = this.$refs.camera.videoWidth;
      const videoHeight = this.$refs.camera.videoHeight;
      
      const xOffset = (videoWidth - videoHeight) / 2;
      

      tempContext.drawImage(
        this.$refs.camera,
        xOffset, // Início do corte na largura para centralizar a área de captura
        0,
        videoHeight, // Usa a altura como base para manter a proporção da foto
        videoHeight, // Usa a altura como base para manter a proporção da foto
        0,
        0,
        500,
        500
      );

      let photoBase64 = tempCanvas.toDataURL('image/jpeg');

      this.$emit('take', photoBase64);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: row;
}

body {
  display: flex;
  justify-content: center;
}

.web-camera {
  margin: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 390px;

  .camera-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 200px;
      height: 240px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }
  .is-rounded {
    margin-right: 10px;
  }
  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 4rem 0 0 -4.5rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
