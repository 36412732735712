<template>
  <div>
    <modal
      id="modalFoto"
      titulo="GERAL.ADICIONAR_FOTO"
      :exibir="exibir"
      @fechar="fecharModal"
      :campos="$refs"
      :ocultarRodape="true"
      :tamanho="'lg'"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="d-flex justify-content-center align-items-center">
              <web-cam @take="take" ref="webCamRef" />
              <div class="container-photo">
                <img
                  class="photo"
                  :src="photoUrl"
                  alt="Foto tirada"
                  v-if="photoUrl"
                />
                <input-foto class="mt-3" @foto-selecionada="take" />
              </div>
            </div>
            <hr />

            <div class="d-flex justify-content-between w-100 mt-3">
              <b-button  variant="danger" @click="fecharModal">
                {{ $t('GERAL.FECHAR') }}
              </b-button>
              <b-button
                class=" ml-2"
                variant="primary"
                @click="salvarFoto"
              >
                {{ $t('GERAL.SALVAR') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
// Utils & Aux:

//Components:
import Modal from '@/components/modal/Modal.vue';
import WebCam from '@/components/foto/WebCam.vue';
import InputFoto from '@/components/foto/InputFoto.vue';

// Services:
export default {
  name: 'ModalFoto',
  components: {
    Modal,
    WebCam,
    InputFoto,
  },
  props: {
    exibir: { type: Boolean, default: true },
  },
  data() {
    return {
      photoUrl: null,
    };
  },
  methods: {
    take(base64) {
      this.photoUrl = base64;
    },
    salvarFoto() {
      if (this.photoUrl !== null) this.$emit('salvarFoto', this.photoUrl);
      this.fecharModal();
    },
    fecharModal() {
      const webCamComponent = this.$refs.webCamRef;
      if (webCamComponent) {
        webCamComponent.stopCameraStream();
      }
      this.photoUrl = '';
      this.$emit('fechar');
    },
  },
};
</script>

<style lang="scss">
.photo {
  border: solid 1px #000;
  width: 190px;
  height: 190px;
  object-fit: contain;
  background: gray;
  display: block;
}
.container-photo {
  margin-top: 2.5px;
}
</style>
