<template>
  <div>
    <div class="mb-4">
      <topbar :titulo="tituloBtn" :breadcrumbs="breadcrumbs">
        <template #header>
          <div class="d-flex justify-content-between ml-md-4 gap-1">
            <b-button variant="secondary" class="" @click="retornar">
              {{ $t('GERAL.VOLTAR') }}
            </b-button>
          </div>
        </template>
      </topbar>
      <dados-pessoais
        ref="dadosPessoais"
        :form="form"
        :dadosEndereco="dadosEndereco"
      />
      <componente-contatos
        :form="form"
        @criar="openModal"
        @editar="openModal"
      />
      <componente-endereco
        :form="form"
        @criar="openModal"
        @editar="openModal"
      />
      <div
        class="d-flex flex-column flex-md-row justify-content-between justify-content-md-end ml-md-4 gap-1"
      >
        <b-button
          variant="info"
          class="mr-md-2 info"
          @click="validarFormulario('matricula')"
        >
          {{ $t('RH.SALVAR_CADASTRAR_MATRICULA') }}
        </b-button>
        <b-button variant="primary" @click="validarFormulario('pessoa')">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </div>

    <modal-contato
      :id="id"
      :exibir="modais.contato"
      :form="form"
      :contato="modais.contatoDados"
      :ehEdicao="ehEdicao"
      @fechar="closeModal('contato')"
    />
    <modal-endereco
      :exibir="modais.endereco"
      :form="form"
      :endereco="modais.enderecoDados"
      :id="id"
      @fechar="closeModal('endereco')"
    />
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';
// import breadcrumb from '@/common/utils/breadcrumb';
import rotas from '@/common/utils/rotas';

// Services:
import PessoaService from '@/common/services/pessoa/pessoa.service';

// Models:
import { Pessoa } from '@/common/models';
import { Contato } from '@/common/models';
import { Endereco } from '@/common/models';

// components
import ModalContato from '@/views/rh/pessoas/components/contatos/Modal';
import ModalEndereco from '@/views/rh/pessoas/components/enderecos/Modal';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
import DadosPessoais from '@/views/rh/pessoas/components/DadosPessoais';
import ComponenteContatos from '@/views/rh/pessoas/components/Contatos';
import ComponenteEndereco from '@/views/rh/pessoas/components/Endereco';
import { NIL as idEmpty } from 'uuid';

export default {
  components: {
    ModalContato,
    ModalEndereco,
    DadosPessoais,
    ComponenteContatos,
    ComponenteEndereco,
    Topbar,
  },
  props: {
    exibir: { type: Boolean, default: false },
    item: { type: Object, default: null },
  },
  watch: { '$route.name': 'verificaNovaOuEditar' },
  data() {
    return {
      ehEdicao: { edicao: false, item: {} },
      id: this.$route.params.id,
      form: new Pessoa({}),
      dadosPessoais: null,
      formEstadoId: '',
      dadosEndereco: {},
      matricula: {
        id: '',
        btnText: this.$t('RH.CADASTRAR_MATRICULA'),
      },
      mensagemValidacao: [],
      modais: {
        contato: false,
        contatoDados: {},
        endereco: false,
        enderecoDados: {},
      },
      breadcrumbs: [
        { titulo: this.$t('RH.RECURSOS_HUMANOS') },
        { titulo: this.$t('RH.PESSOAS') },
        {
          titulo:
            this.$route.name == 'nova-pessoa'
              ? this.$t('GERAL.CADASTRAR')
              : this.$t('GERAL.EDITAR'),
        },
      ],
    };
  },
  mounted() {
    this.verificaNovaOuEditar();
  },
  computed: {
    linkRetornar() {
      return this.$route?.params?.linkRetornar
        ? this.$route?.params?.linkRetornar
        : 'pessoas';
    },
    tituloBtn() {
      return this.$route.name == 'nova-pessoa'
        ? this.$t('GERAL.CADASTRAR')
        : this.$t('GERAL.EDITAR');
    },
    titulo() {
      return !this.item ? this.$t('GERAL.CADASTRAR') : this.$t('GERAL.EDITAR');
    },
    mostrarAcoes() {
      return this.podeEditar || this.podeExcluir;
    },
    exibirBtnMatricula() {
      return this.$route.name == 'nova-pessoa' ? false : true;
    },
  },
  methods: {
    verificaNovaOuEditar() {
      this.$route.name == 'nova-pessoa'
        ? (this.form = new Pessoa({}))
        : this.buscarPorId();
    },

    async buscarPorId() {
      this.$store.dispatch(START_LOADING);
      try {
        let result = await PessoaService.buscarPorId(this.id);
        this.form = { ...result.data, id: this.id };
        this.formNacionalidadeId = this.form.nacionalidadeId;

        // Buscando matrícula se houver:
        if (result.data.matricula) {
          this.matricula.id = result.data.matricula.id;
          this.matricula.btnText = this.$t('RH.VISUALIZAR_MATRICULA');
        }
      } catch (error) {
        mensagem.showErrors(error);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },

    // FUNÇÕES MODAL:
    openModal(key, item) {
      this.modais[key] = true;
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : this.adicionaModel(key);
    },
    adicionaModel(key) {
      switch (key) {
        case 'contato':
          this.modais[`${key}Dados`] = new Contato({});
          break;
        case 'endereco':
          this.modais[`${key}Dados`] = new Endereco({});
          break;
        default:
          break;
      }
    },
    closeModal(key) {
      return (this.modais[`${key}`] = false);
    },
    // FUNÇÕES PARA VALIDAÇÃO:
    validarDadosPessoais() {
      return helpers.validarFormulario(this.$refs.dadosPessoais.$refs);
    },
    validarContatos() {
      let validacao = false;
      if (!this.form.contatos.length) {
        return validacao;
      }
      this.form.contatos.forEach((element) => {
        if (element.principal === true) {
          validacao = true;
        }
      });
      return validacao;
    },
    validarEndereco() {
      let validacao = false;
      if (!this.form.enderecos.length) {
        return validacao;
      }
      this.form.enderecos.forEach((element) => {
        if (element.principal === true) {
          validacao = true;
        }
      });
      return validacao;
    },
    validacoesGerais() {
      this.mensagemValidacao = [];
      if (!this.validarDadosPessoais()) {
        this.mensagemValidacao.push('preencher campos em dados pessoais');
      }
      if (!this.validarContatos()) {
        this.mensagemValidacao.push('adicionar um contato principal');
      }
      if (!this.validarEndereco()) {
        this.mensagemValidacao.push('adicionar um endereço principal');
      }
      return this.mensagemValidacao;
    },
    validarFormulario(rotaDesejada) {
      const mensagens = this.validacoesGerais();
      if (mensagens.length > 0) {
        const mensagemCompleta = `Você precisa: ${mensagens.join(' e ')}`;
        return mensagem.erro('Formulário incompleto!', mensagemCompleta + '.');
      }
      this.salvar(rotaDesejada);
    },
    // FUNÇÕES CRUD:
    salvar(rotaDesejada) {
      if (!this.form.foto) {
        this.form.foto = '';
      }
      if (!this.form.fotoUpload) {
        this.form.fotoUpload = '';
      }
      if (this.form.naturalidadeId === '') {
        this.form.naturalidadeId = idEmpty;
      }
      this.$store.dispatch(START_LOADING);
      PessoaService.salvar(this.form)
        .then(({ data }) => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.id
              ? this.$t('PESSOAS.MENSAGEM_ATUALIZACAO')
              : this.$t('PESSOAS.MENSAGEM_CADASTRADO')
          );

          // Redirecionamento para matrícula ou para pessoas:
          let dados = data.data.id;

          rotaDesejada == 'matricula'
            ? this.irPara('nova-matricula', '', '', dados)
            : this.irPara('pessoas');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, query, retorno, dados) {
      rotas.irPara(rota, query, retorno, dados);
      this.retornarParaTopoTela();
    },
    retornarParaTopoTela() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    retornar() {
      this.$router.push({ name: 'pessoas' });
    },
  },
};
</script>
