<template>
  <div>
    <titulo @criar="criar" />
    <b-table
      :items="form.enderecos"
      :fields="campos"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      show-empty
      responsive
      striped
      hover
    >
      <template #cell(acoes)="item">
        <div class="flex">
          <b-dropdown variant="outline-default">
            <b-dropdown-item @click="editar(item.item)">{{
              $t('GERAL.EDITAR')
            }}</b-dropdown-item>
            <b-dropdown-item @click="confirmarExclusao(item)">{{
              $t('GERAL.EXCLUIR')
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import mensagem from '@/common/utils/mensagem';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import PessoaService from '@/common/services/pessoa/pessoa.service';

import Titulo from './enderecos/Titulo';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: { Titulo },
  data() {
    return {
      id: this.$route.params.id,
      campos: [
        {
          key: 'cep',
          label: this.$t('PESSOAS.CEP'),
        },
        {
          key: 'logradouro',
          label: this.$t('PESSOAS.LOGRADOURO'),
        },
        {
          key: 'numero',
          label: this.$t('PESSOAS.NUMERO'),
        },
        {
          key: 'complemento',
          label: this.$t('PESSOAS.COMPLEMENTO'),
        },
        {
          key: 'tipoEnderecoDescricao',
          label: this.$t('PESSOAS.TIPO_ENDERECO'),
        },
        {
          key: 'principal',
          label: this.$t('PESSOAS.ENDERECO_PRINCIPAL'),
          formatter: (value) => {
            return value ? 'Sim' : 'Não';
          },
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    editar(endereco) {
      this.$emit('editar', 'endereco', endereco);
    },
    criar() {
      this.$emit('criar', 'endereco');
    },
    async confirmarExclusao(endereco) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        this.$t('PESSOAS.CONFIRMAR_EXCLUIR_CONTATO')
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(endereco);
      }
    },
    async removerEndereco(id, endereco) {
      this.$store.dispatch(START_LOADING);
      PessoaService.removerEndereco(id)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('PESSOAS.ENDERECO_EXCLUIDO')
          );
          this.form.enderecos.splice(endereco.index, 1);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    excluir(endereco) {
      if (!this.id) {
        this.form.enderecos.splice(endereco.index, 1);
        return;
      }
      this.removerEndereco(endereco.item.id, endereco);
    },
  },
};
</script>
