<template>
  <titulo-btns :title="$t('PESSOAS.ENDERECO') + '*'" class="mt-4">
    <b-button variant="primary" @click="criar">{{
      $t('PESSOAS.NOVO_ENDERECO')
    }}</b-button>
  </titulo-btns>
</template>
<script>
import TituloBtns from '@/components/headers/TituloBtns.vue';
export default {
  components: { TituloBtns },
  methods: {
    criar() {
      this.$emit('criar');
    },
  },
};
</script>
